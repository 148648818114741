import React from "react";
import './footer-styles.css'

export const FooterComponent = () =>

    <footer className="site-footer pr-0 pl-0">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <h6>Find us at</h6>
                    <p className="text-justify">
                        Prime Guardian Cyber Security Services<br/>
                        B-Hub<br/>
                        Cardinal Cleemis CIET,<br/>
                        Thiruvananthapuram,<br/>
                        Kerala 695015<br/>
                        Contact No: +91 9310505831
                    </p>
                </div>

                <div className="col-xs-6 col-md-3">
                    <h6>Categories</h6>
                    <ul className="footer-links">
                        <li>
                            <a href="/services">Services</a></li>
                        <li>
                            <a href="/careers">Careers</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="#">Blogs</a>*/}
                        {/*</li>*/}
                    </ul>
                </div>

                <div className="col-xs-6 col-md-3">
                    <h6>Quick Links</h6>
                    <ul className="footer-links">
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <hr/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-8 col-sm-6 col-xs-12">
                    <p className="copyright-text">Copyright &copy; 2021 All Rights
                        Reserved by
                        <a href="/"> Prime Guardian</a>.
                    </p>
                </div>

                <div className="col-md-4 col-sm-6 col-xs-12">
                    <ul className="social-icons">
                        <li><a className="facebook" href="#">
                            <i className="fab fa-facebook-f"/></a></li>
                        <li><a className="twitter" href="#">
                            <i className="fab fa-twitter"/></a>
                        </li>
                        <li><a className="dribbble" href="#">
                            <i className="fab fa-dribbble"/></a></li>
                        <li><a className="linkedin" href="#">
                            <i className="fab fa-linkedin"/></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>;
