import React from "react";

export default class AccordionComponent extends React.Component {

    render() {
        return <div className="row accordion mb-4" id="accordionExample">
            <div className="col-lg-6 col-sm-12 col-xs-12">
                <div className="card mb-2" data-toggle="collapse"
                     data-target="#collapseOne">
                    <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Vulnerability Assessment Services <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Vulnerability assessment services is an automated process to identify
                            vulnerabilities present in the network infrastructure, the assessment is conducted
                            with the help of vulnerability scanners like Nessus, Qualys. The scan reports
                            consist of identified vulnerabilities with severity rating on the basis of the
                            impact of vulnerability exploit, mitigation techniques.
                        </div>
                    </div>
                </div>
                <div className="card mb-2"
                     data-toggle="collapse"
                     data-target="#collapseTwo">
                    <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                External Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            External Penetration testing is an approach to test the organization’s asset exposed
                            over internet. The external penetration test simulates the attack scenarios from an
                            external adversary’s perspective, test scenarios identify and exploit
                            vulnerabilities on systems, services and applications exposed to the internet.
                        </div>
                    </div>
                </div>
                <div className="card mb-2" data-toggle="collapse"
                     data-target="#collapseThree">
                    <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                Internal Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Internal Penetration testing is analysis of an organization’s network, by conducting
                            the penetration testing from inside the network. Assessment simulates malicious
                            insider or an attacker that has gained access to the internal network, and attempts
                            end user machine compromise, escalating privileges or malware installation.
                        </div>
                    </div>
                </div>
                <div className="card mb-2" data-toggle="collapse"
                     data-target="#collapseFour">
                    <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                Web Application Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Automated and manual techniques are used to explore the website and associated
                            databases. Comprehensively web application assessment covers the major aspects of
                            application security (e.g. authentication, authorization, session management,
                            input/out validations, injection, misconfigurations, privilege escalations,
                            sensitive data handling/exposure). Web application testing is conducted against
                            industry best practices and standards like OWASP.
                        </div>
                    </div>
                </div>
                <div className="card mb-2" data-toggle="collapse"
                     data-target="#collapseAtm">
                    <div className="card-header" id="atm">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed wrap-text" type="button" data-toggle="collapse"
                                    data-target="#collapseAtm" aria-expanded="false"
                                    aria-controls="collapseAtm">
                                Application Threat Modeling and Design Reviews <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseAtm" className="collapse" aria-labelledby="atm"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Application assessment incorporates guidance from OWASP and our architecture assessment
                            methodology is based on SABSA, NIST Cybersecurity Frameworks. Assessment is tailored on the
                            basis of the required security standard or according to the organization’s requirements.
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-sm-12">
                <div className="card mb-2" data-toggle="collapse"
                     data-target="#collapseFive">
                    <div className="card-header" id="headingFive">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">
                                Mobile Application Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Comprehensive assessment of mobile applications and devices against the security
                            standards such as OWASP and industry best practices. Mobile application testing
                            includes: Static and Dynamic testing.
                        </div>
                    </div>
                </div>
                <div className="card mb-2" data-toggle="collapse"
                     data-target="#collapseSix">
                    <div className="card-header" id="headingSix">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Wireless Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Wireless assessment identifies the critical vulnerabilities that are present in
                            wireless infrastructure. Our approach focuses on identifying the potential loopholes
                            in the network which may lead to unauthenticated access to organization’s internal
                            network, determining the risk severity understanding the vulnerability and
                            recommending appropriate mitigation techniques.
                        </div>
                    </div>
                </div>
                <div className="card mb-2 col-xs-12" data-toggle="collapse"
                     data-target="#collapseSeven">
                    <div className="card-header" id="headingSeven">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseSeven" aria-expanded="false"
                                    aria-controls="collapseSeven">
                                Thick Client Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Thick client application testing requires an approach from an architecture point of view.
                            Thick client penetration testing involves both local and serve-side processing and in
                            certain cases the application use proprietary protocols for communication. The assessment is
                            covered in different phases such as Information gathering, Traffic analysis, Client side and
                            Server-side analysis.
                        </div>
                    </div>
                </div>
                <div className="card mb-2 col-xs-12" data-toggle="collapse"
                     data-target="#collapseEight">
                    <div className="card-header" id="headingEight">
                        <h2 className="mb-0">
                            <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseEight" aria-expanded="false"
                                    aria-controls="collapseEight">
                                API Penetration Testing <i className="fas fa-angle-down"/>
                            </button>
                        </h2>
                    </div>

                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight"
                         data-parent="#accordionExample">
                        <div className="card-body">
                            Application Programming Interfaces are utilized to connect services and transfer data, API
                            provides easiest access point for entry and data theft. API penetration testing emulates an
                            adversary or any malicious entity targeting an API end points and attempting to impact the
                            confidentiality, integrity or availability of a resource.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}