import React from "react";
import {Fade} from "react-reveal";

export default class SocialEngComponent extends React.Component {

    render() {
        return <Fade right>
            <div className="row mb-3 pl-3 pr-3" id="social-eng">
                <div className="col-lg-6 col-sm-12"></div>
                <div className="col-lg-6 col-sm-12">
                    <p className="service-title-font">Social Engineering</p>
                    <p className="service-font">
                        Combination of both human and electronic techniques to simulate real world attack scenarios are
                        utilized to test an organizations resilience against social engineering attacks. Malicious users
                        are often more successful at breaching a network infrastructure through social engineering than
                        through traditional techniques.
                    </p>
                </div>
            </div>
        </Fade>
    }
}