import React from "react";
import {Fade} from "react-reveal";

export default class IotComponent extends React.Component{

    render() {
        return <Fade right>
            <div className="row mb-3 pl-3 pr-3" id="social-eng">
                <div className="col-lg-6 col-sm-12"></div>
                <div className="col-lg-6 col-sm-12">
                    <p className="service-title-font">Security Analysis of Industrial Systems (IOT, SCADA) </p>
                    <p className="service-font">
                        Combination of penetration testing and exploitation experience with the knowledge of
                        industrial ICS systems the approach is developed to test and determine the security
                        posture of ICS/SCADA systems of an organization. An IoT pentesting usually begins by
                        mapping the whole attack surface of the solutions, pinpointing vulnerabilities and
                        executing exploitation later accompanied by post exploitation. IoT pentesting
                        evaluates and exploits numerous components available in an IoT device and the
                        pentesting process is concluded with a detailed technical report.
                    </p>
                </div>
            </div>
        </Fade>
    }
}