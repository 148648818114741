import React from "react";

export default class ContactFormComponent extends React.Component{

    render() {
        return (
                <form>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="first">First<span className="red">*</span></label>
                            <input type="text" className="form-control" id="first" placeholder="First name"/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="last">Last<span className="red">*</span></label>
                            <input type="text" className="form-control" id="last" placeholder="Last name"/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4">Email<span className="red">*</span></label>
                            <input type="email" className="form-control" id="inputEmail4" placeholder="Email"/>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="phone">Phone<span className="red">*</span></label>
                            <input type="number" className="form-control" id="phone" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="company">Company<span className="red">*</span></label>
                        <input type="text" className="form-control" id="company"
                               placeholder="Company Name"/>
                    </div>

                    <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="gridCheck"/>
                            <label className="form-check-label" htmlFor="gridCheck">
                                Subscribe for latest updates
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Message</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Send message</button>
                </form>
        );
    }
}