import React from "react";
import Fade from "react-reveal/Fade";
import web from "../../images/web-design.png";
import seo from "../../images/seo.png";
import gd from "../../images/graphics-design.png";
import mark from "../../images/marketing.png";

const CoreComponent = () => <div className="">
    <div className="row core-value-text d-none d-sm-block">
        <h2>Our Core Values</h2>
    </div>
    <div className="container-fluid gtco-features" id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <Fade left>
                        <h2 className="core-font-header"> Explore The Services<br/>
                            We Offer For You </h2>
                        <p className="core-align service-font">Prime Guardian provides a wide range of security services, including
                            Penetration Testing, GRC services, Network Infrastructure Audits, Enterprise Security
                            Architecture which focus on determining the security level of an organization’s key systems
                            and infrastructure by performing various kinds of assessment on the basis of client
                            requirements.
                        </p>
                    </Fade>
                    <div className="row core-value-text d-block d-sm-none right-align">
                        <h2>Our Core Values</h2>
                    </div>
                </div>
                <div className="col-lg-8">
                    <svg id="bg-services"
                         width="100%"
                         viewBox="0 0 1000 800">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                <stop offset="0%" stop-color="rgb(1,230,248)" stop-opacity="1"/>
                                <stop offset="100%" stop-color="rgb(29,62,222)" stop-opacity="1"/>
                            </linearGradient>
                        </defs>
                        <path fill-rule="evenodd" opacity="0.102" fill="url(#PSgrad_02)"
                              d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"/>
                    </svg>
                    <div className="row">
                        <div className="col">
                            <Fade right>
                                <div className="card text-center">
                                    <div className="oval"><img className="card-img-top" src={web} alt=""/>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Integrity</h3>
                                        {/*<p className="card-text">Nullam quis libero in lorem accumsan sodales. Nam vel*/}
                                        {/*    nisi*/}
                                        {/*    eget.</p>*/}
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="card text-center">
                                    <div className="oval"><img className="card-img-top" src={mark} alt=""/>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Customer Satisfaction</h3>
                                        {/*<p className="card-text">Nullam quis libero in lorem accumsan sodales. Nam vel*/}
                                        {/*    nisi*/}
                                        {/*    eget.</p>*/}
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="col">
                            <Fade right>
                                <div className="card text-center">
                                    <div className="oval"><img className="card-img-top" src={gd} alt=""/></div>
                                    <div className="card-body">
                                        <h3 className="card-title">Team Work</h3>
                                        {/*<p className="card-text">Nullam quis libero in lorem accumsan sodales. Nam vel*/}
                                        {/*    nisi*/}
                                        {/*    eget.</p>*/}
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="card text-center">
                                    <div className="oval"><img className="card-img-top" src={seo}
                                                               alt=""/></div>
                                    <div className="card-body">
                                        <h3 className="card-title">Growth</h3>
                                        {/*<p className="card-text">Nullam quis libero in lorem accumsan sodales. Nam vel*/}
                                        {/*    nisi*/}
                                        {/*    eget.</p>*/}
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>;

export default CoreComponent;