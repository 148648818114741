import React from "react";
import NavBarComponent from "../../../../Header/NavBarComponent";
import {FooterComponent} from "../../../../Footer/FooterComponent";

export default class SecureCodeReviewComponent extends React.Component {
    render() {
        return (
            <>
                <NavBarComponent
                    history={this.props.history}
                />
                <div className="pr-5 pl-5">
                    <h1>Secure Code Review</h1>
                    <hr/>
                    <div className="row accordion mb-3" id="accordionExample">
                        <div className="col-lg-6 col-sm-12">
                            <p className="service-title-font">Some catchy services statement here</p>
                            <p className="service-font">
                                We review the target code against security best practices and categorize the findings
                                based on the weakness categories (e.g., authentication, authorization). The identified
                                findings and weakness are reported with the risk ratings and are summarized in a report
                                with the recommendations that helps development team in improving the code base quality.
                            </p>
                        </div>
                        <div className="col-lg-6 col-sm-12"></div>
                    </div>
                </div>
                <FooterComponent/>
            </>
        )
    }
}