import React from "react";
import NavBarComponent from "../Header/NavBarComponent";
import {FooterComponent} from "../Footer/FooterComponent";
import {Fade} from "react-reveal";
import test from "../../images/test.png";
import banner from "../../images/about.jpg";
export default class AboutComponent extends React.Component {
    render() {
        return (
            <div className="padding">
                <NavBarComponent
                    history={this.props.history}
                />
                <div className='banner-adjust mb-3'>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="row pt-banner">
                                    <div className="col-lg-12 center-block text-center">
                                        <p className="banner-pt-font pl-4">About Us</p>
                                    </div>
                                </div>
                                <Fade left>
                                    <div className="col-lg-6 d-none d-sm-block">
                                        <img src={test} alt="Could not load banner" className="w-100 banner-image"/>
                                    </div>
                                </Fade>
                                <div className="col-lg-6 col-xs-12 p-2 d-none d-sm-block">
                                    <p className="banner-font-secondary text-justify">Our broad cyber security knowledge and deep industry experience allows us to develop tailored
                                        approach in providing services to protect your business. Prime Guardian is a provider of cyber
                                        security services for rapidly growing Banking, Financial and IT industries.
                                        We are a team of consultants specializing in the domain of Information Security.
                                        Prime Guardian's experts are highly qualified and certified professionals (CISSP, CISA, CISM,
                                        CRISC, OSCP, C|EH, C|HFI, E|CSA, etc.) in the field of Computer Networks, Network Security
                                        Assessment & Penetration Testing, Application Security Assessment & Penetration Testing, Risk and
                                        Compliance and Governance of Enterprise IT.</p>
                                </div>
                            </div>
                        </div>
                        <Fade right>
                            <div className="col-lg-6">
                                <img src={banner} alt="Could not load banner" className="w-100 banner-pt-image about-banner-height"/>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="row pl-5 pr-5">
                    <div className="col-xs-12 p-2 d-block d-sm-none">
                        <p className="justify-content-center">Our broad cyber security knowledge and deep industry experience allows us to develop tailored
                            approach in providing services to protect your business. Prime Guardian is a provider of cyber
                            security services for rapidly growing Banking, Financial and IT industries.
                            We are a team of consultants specializing in the domain of Information Security.
                            Prime Guardian's experts are highly qualified and certified professionals (CISSP, CISA, CISM,
                            CRISC, OSCP, C|EH, C|HFI, E|CSA, etc.) in the field of Computer Networks, Network Security
                            Assessment & Penetration Testing, Application Security Assessment & Penetration Testing, Risk and
                            Compliance and Governance of Enterprise IT.</p>
                    </div>
                </div>
                <hr/>
                <FooterComponent/>
            </div>
        )
    }
}
