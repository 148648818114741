import React from "react";
import NavBarComponent from "../Header/NavBarComponent";
import contact from "../../images/contact_v1.jpg";
import {FooterComponent} from "../Footer/FooterComponent";
import {Fade} from "react-reveal";
import test from "../../images/test.png";

export default class NewContactComponent extends React.Component {
    render() {
        return (
            <div className="padding">
                <NavBarComponent
                    history={this.props.history}
                />
                <div className='banner-adjust mb-3'>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="row pt-banner">
                                    <div className="col-lg-12 center-block text-center">
                                        <p className="banner-pt-font pl-4">Contact our Team</p>
                                    </div>
                                </div>
                                <Fade left>
                                    <div className="col-lg-6 d-none d-sm-block">
                                        <img src={test} alt="Could not load banner" className="w-100 banner-image"/>
                                    </div>
                                </Fade>
                                <div className="col-lg-6 col-xs-12 p-2 d-none d-sm-block">
                                    <p className="">
                                        <span
                                            className='contact-title'> Prime Guardian Cyber Security Services </span><br/>
                                        <br/>
                                        <span className='service-font'>B-Hub<br/>
                                            Cardinal Cleemis CIET,<br/>
                                            Thiruvananthapuram,<br/>
                                            Kerala 695015<br/>
                                            Contact No: +91 9310505831<br/>
                                        </span>
                                        <br/>
                                        <br/>
                                        <span className='contact-title'>For enquiries contact:</span><br/>
                                        <span className='service-font font-italic'>sales@primeguardian.co<br/></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Fade right>
                            <div className="col-lg-6">
                                <img src={contact} alt="Could not load banner" className="w-100 banner-pt-image"/>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="row pl-5 pr-5">
                    <div className="col-xs-12 p-2 d-block d-sm-none">
                        <p className="">
                            <span className='contact-title'> Prime Guardian Cyber Security Services </span><br/>
                            <br/>
                            <span className='service-font'>B-Hub<br/>
                                Cardinal Cleemis CIET,<br/>
                                Thiruvananthapuram,<br/>
                                Kerala 695015<br/>
                                Contact No: +91 4422291918
                                </span>
                        </p>
                    </div>
                </div>
                <FooterComponent/>
            </div>
        )
    }
}