import React from "react";
import"../styles/main.css";
import HeaderComponent from "../components/Header/HeaderComponent";
import CoreComponent from "../components/Core/CoreComponent";
import {FooterComponent} from "../components/Footer/FooterComponent";
import Particles from "../components/Util/Particles";
import Banner from "../components/Header/Banner";

export default class MainContainer extends React.Component {

    render() {
        return <div className="overflow">
            <Particles/>
            <HeaderComponent
                isHome={this.props.isHome}
                history={this.props.history}
            />
            <Banner/>
            <CoreComponent/>
            <FooterComponent/>
        </div>
    }
}