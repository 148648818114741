import React from "react";
import NavBarComponent from "../../../Header/NavBarComponent";
import {FooterComponent} from "../../../Footer/FooterComponent";
import AccordionComponent from "./AccordionComponent";
import RedTeamingComponent from "../RedTeaming/RedTeamingComponent";
import SocialEngComponent from "../SocialEngineering/SocialEngComponent";
import {Fade} from "react-reveal";
import banner from "../../../../images/Technical assessment.jpg";
import test from "../../../../images/test.png";
import SourceCodeReviewComponent from "../SourceCodeReview/SourceCodeReviewComponent";
import IotComponent from "../Iot/IotComponent";

export default class PTComponent extends React.Component {

    state = {
        data: require('./data.json'),
        data2: require('./data2.json')
    }

    render() {
        return <div className="padding">
            <NavBarComponent
                history={this.props.history}/>
            <div className='mb-5'>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="row pt-banner">
                                <div className="col-lg-12 center-block text-center">
                                    <p className="banner-pt-font pl-4">Technical Security Assessment</p>
                                </div>
                            </div>
                            <Fade left>
                                <div className="col-lg-6 d-none d-sm-block">
                                    <img src={test} alt="Could not load banner" className="w-100 banner-image"/>
                                </div>
                            </Fade>
                            <div className="col-lg-6 col-xs-12 p-2 d-none d-sm-block">
                                <p className="banner-font-secondary text-justify">Prime Guardian team's focus is to secure our clients’ information, technology,
                                    and networks from the cyber threats that they face. We provide a
                                    robust technical security assessment service with experience of security
                                    testing infrastructure, applications, physical premises and corporate
                                    processes and procedures. We assist defining testing strategies and
                                    processes, as well as assisting in the integration of testing practice in project
                                    and software development life cycles.</p>
                            </div>
                        </div>
                    </div>
                    <Fade right>
                        <div className="col-lg-6">
                            <img src={banner} alt="Could not load banner" className="w-100 banner-pt-image"/>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="row p-2 d-none d-xl-block"/>
            <div className="row pl-5 pr-5">
                <div className="col-xs-12 p-2 d-block d-sm-none">
                    <p className="text-justify banner-font-secondary">Prime Guardian team's focus is to secure our clients’
                        information, technology,
                        and networks from the cyber threats that they face. We provide a
                        robust technical security assessment service with experience of security
                        testing infrastructure, applications, physical premises and corporate
                        processes and procedures. We assist defining testing strategies and
                        processes, as well as assisting in the integration of testing practice in project
                        and software development life cycles.</p>
                </div>
            </div>
            <div className="pr-4 pl-4" id='penetration-testing'>
                <Fade right>
                    <div className="row mb-3 pl-3 pr-3">
                        <p className="service-title-font">Penetration Testing</p>
                        <p className="service-font">
                            An adversary can perform well planned attack with time and can determine the weakness and
                            vulnerabilities in the information systems and take advantage of gaps to compromise and gain
                            access to the internal network.
                            Prime Guardian offers penetration testing services designed to identify system
                            vulnerabilities, validate existing security controls and provide detailed report with
                            mitigation roadmap.
                            Our team performs industry specific test scenarios and pinpoints vulnerabilities on systems,
                            applications, services and Operating Systems.
                            Types of Penetration Tests provided by Prime Guardian
                        </p>
                    </div>
                    <AccordionComponent/>
                </Fade>
                <hr/>
                <RedTeamingComponent/>
                <hr/>
                <SocialEngComponent/>
                <hr/>
                <SourceCodeReviewComponent/>
                <hr/>
                <IotComponent/>
                <hr/>
            </div>
            <FooterComponent/>
        </div>
    }
}