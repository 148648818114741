import React from "react";
import {Fade} from "react-reveal";

export default class RedTeamingComponent extends React.Component {

    render() {
        return (
            <Fade left>
                <div className="row mb-3 pl-3 pr-3" id="red-teaming">
                    <div className="col-lg-6 col-sm-12">
                        <p className="service-title-font">Red Teaming</p>
                        <p className="service-font">
                            Red Teaming focuses on testing the security posture of an organization, the red teaming
                            attack
                            simulation tests the defense, detection and response capabilities of and organization to
                            protect
                            itself from an outside attack. The simulation includes real world adversarial behaviors and
                            tactics, techniques and procedures (TTPs), providing the capability to measure the security
                            programs true effectiveness when faced with persistence and determined attacks.
                        </p>
                    </div>
                    <div className="col-lg-6 col-sm-12"></div>
                </div>
            </Fade>
        )
    }
}