import React from "react";
import NavBarComponent from "../Header/NavBarComponent";
import {FooterComponent} from "../Footer/FooterComponent";
import ContactFormComponent from "./ContactFormComponent";
import banner from "../../images/logo_banner.png";
import contact from "../../images/contact.jpg";

export default class ContactComponent extends React.Component{

    render() {
        return(
            <div className="padding">
            <NavBarComponent
                history={this.props.history}
            />
            <div className="pr-5 pl-5">
                <p className="service-title-font">Contact our Team</p>
                <p className="service-font">
                    Send us a brief description of how we could help you or call us!
                </p>
                <hr/>
                <div className="row mb-4">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        /*<ContactFormComponent/>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-none d-sm-block">
                        <div className="row justify-content-center">
                            <img src={banner} alt="Could not load banner" className="banner background-contact"/>
                            <p className="text-justify">
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <span className='contact-title'> Prime Guardian Cyber Security Services </span><br/>
                                <br/>
                                <span className='service-font'>B-Hub<br/>
                                Cardinal Cleemis CIET,<br/>
                                Thiruvananthapuram,<br/>
                                Kerala 695015<br/>
                                Contact No: +91 4422291918
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent/>
            </div>
        )
    }
}