import React from "react";
import NavBarComponent from "../../Header/NavBarComponent";
import {FooterComponent} from "../../Footer/FooterComponent";
import {Fade} from "react-reveal";
import test from "../../../images/test.png";
import banner from "../../../images/SOC.jpg";

export default class SOCComponent extends React.Component {

    render() {
        return (
            <div className="padding">
                <NavBarComponent
                    history={this.props.history}
                />
                <div className='banner-adjust mb-5'>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="row pt-banner">
                                    <div className="col-lg-12 center-block text-center">
                                        <p className="banner-pt-font pl-4">SOC Assessment</p>
                                    </div>
                                </div>
                                <Fade left>
                                    <div className="col-lg-6 d-none d-sm-block">
                                        <img src={test} alt="Could not load banner" className="w-100 banner-image"/>
                                    </div>
                                </Fade>
                                <div className="col-lg-6 col-xs-12 p-2 d-none d-sm-block">
                                    <p className="banner-font-secondary text-justify">Security Operations Centre (SOC)
                                        Assessment helps an organization to determine the
                                        maturity level of their security monitoring and incident response capabilities
                                        also
                                        to
                                        enhance their cybersecurity defenses and stop breaches from impacting the
                                        business.
                                        The assessment reviews the components associated with SOC which involves review
                                        of
                                        Incident Management process, Incident Response SLA, Volume Management, SIEM
                                        configuration review, co-relation rules effectiveness check and hardware
                                        configuration
                                        review.</p>
                                </div>
                            </div>
                        </div>
                        <Fade right>
                            <div className="col-lg-6">
                                <img src={banner} alt="Could not load banner" className="w-100 banner-pt-image"/>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="pr-5 pl-5">
                    <Fade left>
                        <div className="row mb-3">
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">Threat Intelligence</p>
                                <p className="service-font">
                                    Threat intelligence enhances the visibility of the threat landscape and informs
                                    about the threat actors
                                    behaviors, attack patterns and trends to monitor.<br/>
                                    Threat intelligence provides the guided knowledge base that helps to prevent and
                                    mitigate cyber-
                                    attacks, provides context of attacker motivation and capabilities and what indicator
                                    of compromise to
                                    look for in systems in case of compromises, helps to make informed decisions about
                                    security.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                            </div>
                        </div>
                    </Fade>
                    <hr/>
                    <Fade right>
                        <div className="row mb-3" id='policy-proc'>
                            <div className="col-lg-6 col-sm-12">
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">Threat Hunting</p>
                                <p className="service-font">
                                    Threat hunting is a crucial part of the process of identifying and handling threats.
                                    Our services helps to
                                    identify threats either by our expert threat hunting team or by the help of AI
                                    platform. Manual and AI
                                    approach together ensure the identification over a larger landscape of network,
                                    end-point, application
                                    and user behaviour.
                                </p>
                            </div>
                        </div>
                    </Fade>
                </div>
                <FooterComponent/>
            </div>
        )
    }
}