import React from "react";
import NavBarComponent from "./NavBarComponent";

export default class HeaderComponent extends React.Component {

    render() {
        return <div>
            <NavBarComponent
                isHome={this.props.isHome}
                history={this.props.history}
            />
        </div>
    }
}