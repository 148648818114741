import React from "react";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import MainContainer from "./MainContainer";
import AboutComponent from "../components/About/AboutComponent";
import PTComponent from "../components/Services/TSA/PenTest/PTComponent";
import GRCComponent from "../components/Services/GRC/GRCComponent";
import SOCComponent from "../components/Services/SOC/SOCComponent";
import ContactComponent from "../components/Contact/ContactComponent";
import SecureCodeReviewComponent from "../components/Services/TSA/PenTest/SecureCodeReview/SecureCodeReviewComponent";
import CareersComponent from "../components/Careers/CareersComponent";
import NewContactComponent from "../components/Contact/NewContactComponent";

export default class MainManager extends React.Component {

    render() {
        return (
            <div className="overflow">
                <Router>
                    <Route
                        path="/"
                        exact={true}
                        render={(props) => <MainContainer
                            history={props.history}
                            isHome={true}
                        />}
                    />
                    <Route
                        path='/about'
                        exact={true}
                        render={(props) => <AboutComponent
                            history={props.history}
                        />}
                    />
                    <Route
                        path='/services'
                        exact={true}
                        render={(props) => <PTComponent
                            history={props.history}
                        />}
                    />
                    <Route
                        path='/scr'
                        exact={true}
                        render={(props) => <SecureCodeReviewComponent
                            history={props.history}
                        />}
                    />
                    <Route
                        path='/grc'
                        exact={true}
                        render={(props) => <GRCComponent
                            history={props.history}
                        />}
                    />
                    <Route
                        path='/soc'
                        exact={true}
                        render={(props) => <SOCComponent
                            history={props.history}
                        />}
                    />
                    <Route
                        path='/contact'
                        exact={true}
                        render={(props) => <NewContactComponent
                            history={props.history}
                        />}
                    />
                    <Route
                        path='/careers'
                        exact={true}
                        render={(props) => <CareersComponent
                            history={props.history}
                        />}
                    />
                </Router>
            </div>
        )
    }
}