import React from "react";
import {Fade} from "react-reveal";

export default class SourceCodeReviewComponent extends React.Component {

    render() {
        return (
            <Fade left>
                <div className="row mb-3 pl-3 pr-3" id="secure-code-review">
                    <div className="col-lg-6 col-sm-12 col-xs-12">
                        <p className="service-title-font">Source Code Review</p>
                        <p className="service-font">
                            We review the target code against security best practices and categorize the findings based
                            on the weakness categories (e.g., authentication, authorization). The identified findings
                            and weakness are reported with the risk ratings and are summarized in a report with the
                            recommendations that helps development team in improving the code base quality.
                        </p>
                    </div>
                    <div className="col-lg-6 col-sm-12"></div>
                </div>
            </Fade>
        )
    }
}