import React from "react";
import NavBarComponent from "../Header/NavBarComponent";
import {FooterComponent} from "../Footer/FooterComponent";
import careers from "../../images/careers.jpg";
import {Fade} from "react-reveal";
import test from "../../images/test.png";

export default class CareersComponent extends React.Component {

    render() {
        return (
            <div className="padding">
                <NavBarComponent
                    history={this.props.history}
                />
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="row pt-banner">
                                <div className="col-lg-12 center-block text-center">
                                    <p className="banner-pt-font pl-4">Careers</p>
                                </div>
                            </div>
                            <Fade left>
                                <div className="col-lg-6 d-none d-sm-block">
                                    <img src={test} alt="Could not load banner" className="w-100 banner-image"/>
                                </div>
                            </Fade>
                            <div className="col-lg-6 col-xs-12 p-2 d-none d-sm-block">
                                <p className="banner-font-secondary">Prime Guardian is on continuous search of bright and confident brains who accept the
                                    challenge in real world as an individual and work with team as team player and supports
                                    with passion and enthusiasm.<br/>
                                    Join us and discover the challenging opportunities in the world of cyber security.</p>
                            </div>
                        </div>
                    </div>
                    <Fade right>
                        <div className="col-lg-6">
                            <img src={careers} alt="Could not load banner" className="w-100 banner-pt-image"/>
                        </div>
                    </Fade>
                </div>
                <hr/>
                <div className="row pl-5 pr-5">
                    <div className="col-xs-12 p-2 d-block d-sm-none">
                        <p className="text-justify banner-font-secondary">Prime Guardian is on continuous search of bright and confident brains who accept the
                            challenge in real world as an individual and work with team as team player and supports
                            with passion and enthusiasm.<br/>
                            Join us and discover the challenging opportunities in the world of cyber security.</p>
                    </div>
                </div>
                <div className="pr-5 pl-5">
                    <div className="row">
                        <p className="service-title-font">Join our team</p>
                        {/*<p className="service-font">*/}
                        {/*    Prime Guardian is on continuous search of bright and confident brains who accept the*/}
                        {/*    challenge in real world as an individual and work with team as team player and supports*/}
                        {/*    with passion and enthusiasm.<br/>*/}
                        {/*    Join us and discover the challenging opportunities in the world of cyber security.*/}
                        {/*</p>*/}
                    </div>
                    <div className="row accordion mb-3" id="accordionExample">
                        <div className="col-lg-12 col-sm-12">
                            <div className="card mb-2"
                                 data-toggle="collapse"
                                 data-target="#collapseTwo">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link wrap-text" type="button" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="true"
                                                aria-controls="collapseTwo">
                                            Information Security Consultant (VAPT) <i className="fas fa-angle-down"/>
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo"
                                     data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
                                            <span className="font-weight-bold">Role Type:</span> Permanent Role<br/>
                                            <span className="font-weight-bold">Location:</span> India/KSA/Oman/Egypt<br/>
                                            <span className="font-weight-bold">Requirements:</span><br/>
                                        </p>
                                        <ul>
                                            <li>1-5 years of relevant experience in Web Application, Network, Thick client and Mobile Application Penetration Testing.</li>
                                            <li>Strong understanding of various aspects of information security and methodologies to assess the security of web, mobile applications, networks, configuration review.</li>
                                            <li>Good report writing skills.</li>
                                            <li>Willing to work on-site in client locations in the Middle East (Saudi Arabia, Jordan, Oman, Egypt).</li>
                                            <li>Bachelor's in Computer Science / IT / InfoSec is a must.</li>
                                            <li>Security certifications such as CEH/eCPPT/ECSA/OSCP are a plus.</li>
                                            <li>Experience in Bug bounty/CTFs are a plus.</li>
                                            <li>Active security blogging/write ups are a plus.</li>
                                        </ul>
                                        <hr/>
                                        <p>Interested Candidates can send their updated CV to <span className="font-italic">career@primeguardian.co</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-2" data-toggle="collapse"
                                 data-target="#collapseOne">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link collapsed wrap-text" type="button" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="false"
                                                aria-controls="collapseOne">
                                            GRC Consultant <i className="fas fa-angle-down"/>
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne"
                                     data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
                                            <span className="font-weight-bold">Role Type:</span> Permanent Role<br/>
                                            <span className="font-weight-bold">Location:</span> India/KSA/Oman/Egypt<br/>
                                            <span className="font-weight-bold">Requirements:</span><br/>
                                        </p>
                                        <ul>
                                            <li>3-6 years of relevant experience in Governance/ Risk and Compliance.</li>
                                            <li>Good experience of Risk assessment, Gap Analysis, Auditing, Enterprise Architecture Review.</li>
                                            <li>Experience with User Access, Change Management, Developing policy and procedure.</li>
                                            <li>Good understanding of regulatory standards and compliance such as ISO 27k, PCI-DSS</li>
                                            <li>Willing to work on-site in client locations in the Middle East (Saudi Arabia, Jordan, Oman, Egypt).</li>
                                            <li>Bachelor's in Computer Science / IT / InfoSec is a must.</li>
                                            <li>Security Certifications such as ISO 27k/CISA is preferred.</li>
                                            <li>CISSP/CISM is a plus.</li>
                                        </ul>
                                        <hr/>
                                        <p>Interested Candidates can send their updated CV to <span className="font-italic">career@primeguardian.co</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-2" data-toggle="collapse"
                                 data-target="#collapseThree">
                                <div className="card-header" id="headingThree">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link collapsed wrap-text" type="button" data-toggle="collapse"
                                                data-target="#collapseThree" aria-expanded="false"
                                                aria-controls="collapseThree">
                                            Splunk Admin <i className="fas fa-angle-down"/>
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                     data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>
                                            <span className="font-weight-bold">Role Type:</span> Permanent Role<br/>
                                            <span className="font-weight-bold">Location:</span> India/KSA/Oman/Egypt<br/>
                                            <span className="font-weight-bold">Requirements:</span><br/>
                                        </p>
                                        <ul>
                                            <li>Minimum three years of hands-on experience as a Splunk Administrator performing the installation, testing, operation, troubleshooting, and maintenance of Splunk.</li>
                                            <li>Installation, configuration, maintenance and troubleshooting of Splunk systems.</li>
                                            <li>Experience with Splunk issues and production support.</li>
                                            <li>Experience on-boarding new Splunk logs.</li>
                                            <li>Knowledge of regular expressions.</li>
                                            <li>Experience with logical volume management.</li>
                                            <li>Knowledge of virtualization concepts as well as industry best practices to include virtualization technologies and management tools.</li>
                                            <li>Strong written/verbal communication and interpersonal skills.</li>
                                            <li>Certification like Splunk Certified systems administrator preferred.</li>
                                        </ul>
                                        <hr/>
                                        <p>Interested Candidates can send their updated CV to <span className="font-italic">career@primeguardian.co</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent/>
            </div>
        )
    }
}