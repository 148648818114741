import React from "react";
import NavBarComponent from "../../Header/NavBarComponent";
import {FooterComponent} from "../../Footer/FooterComponent";
import {Fade} from "react-reveal";
import test from "../../../images/test.png";
import banner from "../../../images/GRC.jpg";

export default class GRCComponent extends React.Component {

    render() {
        return (
            <div className="padding">
                <NavBarComponent
                    history={this.props.history}
                />
                <div className='banner-adjust mb-5'>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="row pt-banner">
                                    <div className="col-lg-12 center-block text-center">
                                        <p className="banner-pt-font pl-4">Governance, Risk and Compliance</p>
                                    </div>
                                </div>
                                <Fade left>
                                    <div className="col-lg-6 d-none d-sm-block">
                                        <img src={test} alt="Could not load banner" className="w-100 banner-image"/>
                                    </div>
                                </Fade>
                                <div className="col-lg-6 col-xs-12 p-2 d-none d-sm-block">
                                    <p className="banner-font-secondary text-justify">A strategy for managing an organization’s
                                        governance,
                                        enterprise risk management and compliance against standards and frameworks.
                                        Prime Guardian provides a well-planned strategy to assess and implement a
                                        structured
                                        approach to align IT with business objectives, while effectively managing risk
                                        and
                                        meeting
                                        compliance and regulatory requirements.</p>
                                </div>
                            </div>
                        </div>
                        <Fade right>
                            <div className="col-lg-6">
                                <img src={banner} alt="Could not load banner " className="w-100 banner-pt-image"/>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="row pl-5 pr-5">
                    <div className="col-xs-12 p-2 d-block d-sm-none">
                        <p className="text-justify banner-font-secondary">A strategy for managing an organization’s
                            governance,
                            enterprise risk management and compliance against standards and frameworks.
                            Prime Guardian provides a well-planned strategy to assess and implement a
                            structured
                            approach to align IT with business objectives, while effectively managing risk
                            and
                            meeting
                            compliance and regulatory requirements.</p>
                    </div>
                </div>
                <div className="pr-5 pl-5">
                    <Fade left>
                        <div className="row mb-3" id="info-sec">
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">Information Security Risk Assessment </p>
                                <p className="service-font">
                                    The objective of information security risk assessment is to identify the risks
                                    associate with the existing infrastructure, and to provide recommendations to
                                    maximize the protection of confidentiality, integrity and availability while
                                    still providing functionality and usability.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                            </div>
                        </div>
                    </Fade>
                    <hr/>
                    <Fade right>
                        <div className="row mb-3">
                            <div className="col-lg-6 col-sm-12">
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">ISMS/ISO 27001</p>
                                <p className="service-font">
                                <span className='mb-2'>
                                Our experts in Prime Guardian will assist and guide your organization in all areas of the
                                Management Information System (MIS), ranging from assessing your information
                                security against the standard to helping you achieve certification to the
                                standard.
                                <br/>
                                To help you setup and manage your ISMS, we provide an extensive set of services
                                    </span>
                                    <ul className="pt-3">
                                        <li className="">ISMS Audit</li>
                                        <li className="">ISMS Advisory & Implementation</li>
                                        <li className="">ISMS Training & Awareness</li>

                                    </ul>
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <hr/>
                    <Fade left>
                        <div className="row mb-3" id='policy-proc'>
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">Policy and Procedures</p>
                                <p className="service-font">
                                    Reviewing, developing or evaluating information security policies and
                                    recommending the gaps and changes to be developed in the organizations policy
                                    and procedures. The review of policies are done to meet the degree of compliance
                                    against the required standards and recommend the creation of policies where
                                    needed.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                            </div>
                        </div>
                    </Fade>
                    <hr/>
                    <Fade right>
                        <div className="row mb-3" id='security-arch'>
                            <div className="col-lg-6 col-sm-12">
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">Security Architecture</p>
                                <p className="service-font">
                                    Security architecture design review is to determine the design flaws/weakness
                                    and to assess the implemented architecture against industry standards and best
                                    practices. Our assessments incorporate guidance from the Sherwood Applied
                                    Business Security Architecture (SABSA), National Institute of Standard and
                                    Technology (NIST) Cybersecurity Frameworks.
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <hr/>
                    <Fade left>
                        <div className="row mb-3" id="config-review">
                            <div className="col-lg-6 col-sm-12">
                                <p className="service-title-font">Configuration Review</p>
                                <p className="service-font">
                                    We conduct thorough review of the network architecture and configuration to
                                    determine if
                                    appropriate design, configuration, and access controls are in place. the review is
                                    conducted with the help well-known automated tools against the various hardening
                                    standards like CIS benchmark.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                            </div>
                        </div>
                    </Fade>
                </div>
                <FooterComponent/>
            </div>
        )
    }
}