import React from "react";
import mainLogo from "../../images/logo.png";
import { NavHashLink } from 'react-router-hash-link';

export default class NavBarComponent extends React.Component {

    state = {
        section: this.props.section,
        handleClickMode: false
    }

    render() {

        let fixClass = ["container-fluid"];
        if (this.props.isHome) {
            fixClass.push("fix-top")
        }

        if(this.state.handleClickMode === true){
            fixClass.push("white")
        }
        return (
            <div className={fixClass.join(' ')}>
                <nav
                    className={"navbar navbar-expand-lg navbar-light bg-light" + (this.props.isHome === true ? 'navbar-fixed-top bg-transparent' : ' fixed-top')}>
                    <div className="navbar-brand">
                        <img src={mainLogo} alt="Main logo" className="mainLogo-navbar"
                             onClick={() => this.props.history.push('/')}/>
                    </div>
                    <button className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNavDropdown"
                            aria-controls="navbarNavDropdown"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => this.setState({
                                handleClickMode: !this.state.handleClickMode
                            })}
                    >
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="/">Home<span
                                    className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/about">About Us</a>
                            </li>
                            <li className="nav-item active dropdown">
                                <a className="nav-link dropdown-toggle"
                                   href="/services"
                                   id="navbarDropdownMenuLink"
                                   data-toggle="dropdown"
                                   aria-haspopup="true"
                                   aria-expanded="false"
                                >Services
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a className="dropdown-item" href="/services" onClick={()=> this.props.history.push("/services")}>
                                        <div className="dropdown dropleft">
                                            <a className="dropdown-toggle"
                                               data-toggle="dropdown"
                                               aria-haspopup="true"
                                               aria-expanded="false">
                                                Technical Security Assessment
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <NavHashLink
                                                    to='/services'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Penetration testing</NavHashLink>
                                                <NavHashLink
                                                    to='/services#red-teaming'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Red Teaming</NavHashLink>
                                                <NavHashLink
                                                    to='/services#red-teaming'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Social Engineering</NavHashLink>
                                                <NavHashLink
                                                    to='/services#social-eng'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Secure Code Review</NavHashLink>
                                                <NavHashLink
                                                    to='/services#social-eng'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Security Analysis of Industrial Systems</NavHashLink>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item"
                                       href="/grc"
                                       onClick={()=> this.props.history.push("/grc")}
                                    >
                                        <div className="dropdown dropleft">
                                            <a className="dropdown-toggle" id="" data-toggle="dropdown"
                                               aria-haspopup="true"
                                               aria-expanded="false">
                                                GRC
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <NavHashLink
                                                    to='/grc'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Risk Assessment</NavHashLink>
                                                <NavHashLink
                                                    to='/grc#iso-27001'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >ISO 27001</NavHashLink>
                                                <NavHashLink
                                                    to='/grc#policy-proc'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Policy and Procedures</NavHashLink>
                                                <NavHashLink
                                                    to='/grc#security-arch'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Security Architecture Review</NavHashLink>
                                                <NavHashLink
                                                    to='/grc#config-review'
                                                    smooth={true}
                                                    className="dropdown-item"
                                                >Configuration Review</NavHashLink>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item"
                                       href="/soc"
                                       onClick={()=> this.props.history.push("/soc")}>
                                        <div className="dropdown dropleft">
                                            <a className="dropdown-toggle" id="" data-toggle="dropdown"
                                               aria-haspopup="true"
                                               aria-expanded="false">
                                                SOC
                                            </a>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" href="/soc">SOC Assessment</a>
                                                <a className="dropdown-item" href="/soc">Threat Intelligence</a>
                                                <a className="dropdown-item" href="/soc">Threat Hunting</a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/careers">Careers</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/contact">Contact Us</a>
                            </li>
                            {/*<li className="nav-item active">*/}
                            {/*    <a className="nav-link" href="#">Blogs</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </nav>
            </div>)
    }
}